<template>
  <main class="page-container" id="sme-noticeinfo-view">
    <div class="page-component">
      <div class="page-header blind">
        <div class="page-component">
          <div class="page-header-title">
            <h2 class="title">소상공인 정보놀이터</h2>
          </div>
          <div class="page-header-hashtags">
            <p class="text">{{ tags }}</p>
          </div>
        </div>
      </div>
      <div class="page-body">
        <section class="post-view-wrapper">
          <header class="post-view-header">
            <div class="header-category">
              <div class="badge">
                <span class="badge-text">{{ plygrndCtgr3 }}</span>
              </div>
            </div>
            <h3 class="header-title">{{ plygrndTit }}</h3>
            <p class="header-tags">{{ tags }}</p>
          </header>
          <div class="post-view-body">
            <div class="gachi-view" v-html="plygrndCn"></div>
          </div>
        </section>

        <div class="page-buttons" data-align="center">
          <a href="javascript:">
            <button v-if="atchFilePtUrl"  class="btn btn-lg btn-secondary" :disabled="downing" @click="clickDownload">
              <span class="text">{{downing ? '다운로드 중...': '첨부파일' }}<br>[{{ atchFileNm }}]</span>
            </button>
          </a>
        </div>
        <div class="page-buttons" data-align="center">
          <a href="javascript:">
            <div class="list-btn-cont">
              <button class="btn btn-lg btn-primary" @click="goList">
                <span class="text">목록</span>
              </button>
            </div>

            <next-prev-button
                route-name="Playground3View"
                param-key="plygrndId"
                :pre-id="preId"
                :next-id="nextId"
            ></next-prev-button>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {ACT_DOWN_ATTACH_PLAYGROUND3, ACT_GET_PLAYGROUND3} from '../../../store/_act_consts';
import {getItem, lengthCheck, queryToValue, viewTags} from '../../../assets/js/utils';
import {viewCount} from '../../../assets/js/func';
import NextPrevButton from '../../../components/common/NextPrevButton';

export default {
  name: 'Playground3View',
  components: {NextPrevButton},
  watch:{
    $route(to){
      this.plygrndId = queryToValue(to.params.plygrndId, true, 0);
      this.getPlayground();
    }
  },
  data:()=> ({
    plygrndId: 0,
    plygrndCtgr3: '',
    plygrndTit: '',
    tags: '',
    plygrndCn: '',
    atchFileNm:'',
    atchFilePtUrl:'',
    downing: false,
    preId: 0,
    nextId: 0
  }),
  created() {
    this.plygrndId = this.$route.params.plygrndId;
    viewCount(this.plygrndId, 'plygrnd3');
    this.getPlayground();
  },
  methods: {
    goList(){
      this.$router.push({name: 'Playground3', query:{ctgrCode:this.$route.query.ctgrCode}});
    },
    getPlayground() {
      this.$store.dispatch(`community/${ACT_GET_PLAYGROUND3}`, {
        plygrndId: this.plygrndId,
        plygrndCtgrDcd: this.$route.query.ctgrCode
      }).then(res => {
            if (lengthCheck(res)) {
              const item = getItem(res);
              this.plygrndId = item.plygrndId;
              this.plygrndCtgr3 = item.plygrndCtgr3;
              this.plygrndTit = item.plygrndTit;
              this.tags = viewTags(item, 'plygrndTag');
              this.plygrndCn = item.plygrndCn;
              this.atchFileNm = item.atchFileNm;
              this.atchFilePtUrl = item.atchFilePtUrl;
              this.preId = item.preId;
              this.nextId = item.nextId;
            } else {
              this.plygrndId = 0;
            }
          }).catch(e => {
        console.error(e);
        this.plygrndId = 0;
      })
    },
    clickDownload() {
      // window.open(`${this.atchFilePtUrl}`);
      this.downing = true;
      this.$store.dispatch(`community/${ACT_DOWN_ATTACH_PLAYGROUND3}`, {
        plygrndId: this.plygrndId,
        fileName: this.atchFileNm
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.atchFileNm)
        document.body.appendChild(link)
        link.click()
        this.downing = false;
      }).catch(err => {
        console.log(err)
        this.downing = false;
      })
    }
  }
}
</script>
